<template>
  <div>
    <b-overlay :show="loading">
      <b-alert
        variant="danger"
        :show="manual_repayment === undefined && !loading"
      >
        <h4 class="alert-heading">
          Error manual repayment details
        </h4>
        <div class="alert-body">
          <b-link
            class="alert-link"
            :to="{ name: 'client-list-manual-repayments'}"
          >
            List Repayments
          </b-link>
        </div>
      </b-alert>

      <template v-if="manual_repayment != null && manual_repayment != undefined">
        <manual-repayment-details 
          :manual-repayment="manual_repayment" 
          @loading="loading = $event"
          @fetchDetails="fetchManualRepaymentDetails" 
        />
      </template>
    </b-overlay>
  </div>
</template>

<script >
import { get } from "lodash";
import {
  BOverlay, BAlert, BLink
} from 'bootstrap-vue'

import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import ManualRepaymentDetails from "@core/components/shared/manual-repayments/details.vue";

export default {
  components: {
    BOverlay,
    BAlert,
    BLink,

    ManualRepaymentDetails
  },
  data() {
    return {
      loading: false,
      is_paid: false,
      balance: 0,
      total_repaid: 0,
      total_loan_cost: 0,
      agree_to_terms: false,
      errorMessage: "",

      manual_repayment: null
    }
  },
  created(){
    this.fetchManualRepaymentDetails();
  },
  methods: {
    async fetchManualRepaymentDetails(){
      try {
        this.loading = true

        const { manual_repayment_id } = this.$route.params;
        const response = await this.useJwt().clientService.fetchManualRepaymentRecord(manual_repayment_id);
        const manual_repayment = get(response, "data.data");

        this.manual_repayment = manual_repayment;
      } catch (error){
        this.manual_repayment = undefined;

        const error_message = get(error, "response.data.message") || error.message
        this.errorMessage = error_message;

        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Error!',
            icon: 'AlertTriangleIcon',
            variant: 'danger',
            text: error_message
          },
        });
      } finally {
        this.loading = false
      }
    },
  }
}
</script>

<style lang="scss">
  .payment-channel-icon {
    width: 50px;
  }
  .payment-channel-label {
    color: #302861;
    font-weight: bold;
  }
  .font-active {
    color: #302861
  }
  .disabled {
    opacity: 0.3;
    cursor: no-drop;
  }
    .deposit-slip-picker {
      .custom-file-label::after {
        background-color: #302861;
        color: whitesmoke;
        padding-left: 40px;
        padding-right: 40px;
      }
    }
</style>
