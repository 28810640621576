<template>
  <section class="mt-0 mt-md-5">
    <b-row>
      <b-col
          class=""
          cols="12"
          xl="8"
          md="8"
      >
        <!-- Put a badge-->
        <div class="mb-1">
          <b-badge
              pill
              :variant="`light-${resolveStatusVariant(getValueFromSource(manualRepayment, 'status'))}`"
              class="text-capitalize mb-1 py-50 px-2"
          >
            {{ getUserStatusText( getValueFromSource(manualRepayment, 'status') ) }}
          </b-badge>

        </div>

        <div>
          <div>
            <div class="border-primary card-body rounded-lg">
              <div>
                <div class="d-md-flex align-items-center justify-content-between">
                  <div class="mr-1">
                    <div>
                      <p class="m-0 p-0">
                        Monthly Payment
                      </p>
                    </div>
                    <div>
                      <h2>
                        GH¢{{ formatMoney(getValueFromSource(manualRepayment, 'loan.monthly_payment', 0)) }}
                      </h2>
                    </div>
                  </div>
                  <div class="mr-1">
                    <div>
                      <p class="m-0 p-0">
                        Loan Amount
                      </p>
                    </div>
                    <div>
                      <h2>GH¢{{ formatMoney(getValueFromSource(manualRepayment, 'loan.amount', 0)) }}
                      </h2>
                    </div>
                  </div>
                  <div class="mr-1">
                    <div>
                      <p class="m-0 p-0">
                        Total Paid
                      </p>
                    </div>
                    <div>
                      <h2>
                        GH¢{{ formatMoney(repaid_amount) }}
                      </h2>
                    </div>
                  </div>
                  <div class="mr-1">
                    <div>
                      <p class="m-0 p-0">
                        Paid Amount
                      </p>
                    </div>
                    <div>
                      <h2>
                        GH¢{{ formatMoney(amount_paid) }}
                      </h2>
                    </div>
                  </div>
                  <div class="mr-1">
                    <div>
                      <p class="m-0 p-0">
                        Balance
                      </p>
                    </div>
                    <div>
                      <h2>
                        GH¢{{ formatMoney(remaining_balance) }}
                      </h2>
                    </div>
                  </div>
                </div>
              </div>
              <div class="mt-1">
                <b-button
                    v-if="isCurrentUserClient"
                    class=""
                    :disabled="manualRepayment.status !== 'submitted'"
                    variant="primary"
                    @click="cancelPayOffRequest"
                >
                  Cancel Repayment
                </b-button>
              </div>
            </div>
          </div>
        </div>

        <!--payment slips-->
        <div>
          <h5 class="mb-1 mt-2">
            Payment Slips</h5>

          <loan-attachments
              :attachments="manualRepayment.attached_payment_slips"
              :flex-column="false"
              :show-labels="false"
              :no-card-body="true"
          />
        </div>

        <!-- declined and flagged reasons-->
        <div
            v-if="['cancelled', 'declined'].includes(manualRepayment.status) && manualRepayment.flagged_reasons.length > 0"
        >
          <h5
              class="mb-1 mt-2"
              :class="{
            'text-danger': manualRepayment.status === 'declined',
            'text-warning': manualRepayment.status === 'cancelled'
          }"
          >
            <feather-icon
                icon="HelpCircleIcon"
                size="18"
            />
            {{ capitalizeFirstWord(manualRepayment.status) }} Reasons
          </h5>

          <declined-or-flagged-reasons :flagged-reasons="manualRepayment.flagged_reasons"
                                       :variant="manualRepayment.status === 'declined' ? 'danger' : 'warning'"
          />
        </div>
      </b-col>

      <b-col
          cols="12"
          xl="4"
          md="4"
      >
        <div>
          <div class="bg-secondary rounded text-white">
            <div class="card-body">
              <div class="mb-2">
                <h3 class="text-white">
                  Summary
                </h3>
              </div>

              <div>
                <div class="d-flex justify-content-between align-items-center mb-50">
                  <div>
                    <p class="p-0 m-0">
                      Request Date
                    </p>
                  </div>
                  <div><p class="p-0 m-0">
                    {{ formatDate(manualRepayment.created, 'dd MMMM yyyy') }}
                  </p>
                  </div>
                </div>
              </div>
              <div v-if="manualRepayment.status === 'declined'">
                <div class="d-flex justify-content-between align-items-center mb-50">
                  <div>
                    <p class="p-0 m-0">
                      Declined On
                    </p>
                  </div>
                  <div>
                    <p class="p-0 m-0">
                    {{ formatDate(manualRepayment.declined_on, 'dd MMMM yyyy') }}
                  </p>
                  </div>
                </div>
              </div>
              <div v-if="manualRepayment.status === 'approved'">
                <div class="d-flex justify-content-between align-items-center mb-50">
                  <div>
                    <p class="p-0 m-0">
                      Approved Date
                    </p>
                  </div>
                  <div>
                    <p class="p-0 m-0">
                    {{ formatDate(manualRepayment.approved_on, 'dd MMMM yyyy') }}
                  </p>
                  </div>
                </div>
              </div>
              <div>
                <div class="d-flex justify-content-between align-items-center mb-50">
                  <div>
                    <p class="p-0 m-0">
                      Payout Date
                    </p></div>
                  <div><p class="p-0 m-0">
                    {{ formatDate(getValueFromSource(loan, 'payout_date'), 'dd MMMM yyyy') }}
                  </p></div>
                </div>
              </div>

              <div>
                <div class="d-flex justify-content-between align-items-center mb-50">
                  <div><p class="p-0 m-0">
                    Processing Fee
                  </p></div>
                  <div><p class="p-0 m-0">
                    Gh¢ {{ formatMoney(getValueFromSource(loan, 'processing_fee')) }}
                  </p></div>
                </div>
              </div>

              <div>
                <div class="d-flex justify-content-between align-items-center mb-50">
                  <div><p class="p-0 m-0">
                    Payout
                  </p></div>
                  <div>
                    <p class="p-0 m-0">
                      Gh¢ {{ formatMoney(getValueFromSource(loan, 'payout')) }}
                    </p>
                  </div>
                </div>
              </div>

              <div>
                <div class="d-flex justify-content-between align-items-center mb-50">
                  <div>
                    <p class="p-0 m-0">
                    Interest Rate
                    </p>
                  </div>
                  <div>
                    <p class="p-0 m-0">
                      {{ getValueFromSource(loan, 'interest_rate') }} %
                    </p>
                  </div>
                </div>
              </div>

              <div>
                <div class="d-flex justify-content-between align-items-center mb-50">
                  <div><p class="p-0 m-0">
                    Interest
                  </p></div>
                  <div>
                    <p class="p-0 m-0">
                      Gh¢ {{ formatMoney(getValueFromSource(loan, 'interest')) }}
                  </p></div>
                </div>
              </div>

              <div>
                <div class="d-flex justify-content-between align-items-center mb-50">
                  <div><p class="p-0 m-0">
                    Monthly Payment
                  </p></div>
                  <div>
                    <p class="p-0 m-0">
                    Gh¢ {{ formatMoney(getValueFromSource(manualRepayment, 'loan.monthly_payment')) }}
                    </p>
                  </div>
                </div>
              </div>

              <div>
                <div class="d-flex justify-content-between align-items-center mb-50">
                  <div><p class="p-0 m-0">
                    Penal Charge
                  </p></div>
                  <div><p class="p-0 m-0">
                   Gh¢ {{ formatMoney(total_penal_charge) }}
                  </p></div>
                </div>
              </div>

              <div class="mt-2">
                <div class="d-flex justify-content-between align-items-center">
                  <h4 class="text-white">
                    Total Loan Cost
                  </h4>
                  <h4 class="text-white">
                    GH¢ {{ formatMoney(manualRepayment.loan.total_loan_cost + total_penal_charge) }}
                  </h4>

                </div>
              </div>
            </div>
          </div>
        </div>

      </b-col>
    </b-row>
  </section>
</template>

<script>
import {
  BAlert,
  BAvatar,
  BBadge,
  BButton,
  BCard,
  BCardBody,
  BCardHeader,
  BCardText,
  BCol,
  BForm,
  BFormCheckbox,
  BFormFile,
  BFormGroup,
  BFormInput,
  BFormRadio,
  BLink,
  BOverlay,
  BPopover,
  BRow
} from 'bootstrap-vue'
import DeclinedOrFlaggedReasons from "@/@core/components/shared/loans/DeclinedOrFlaggedReasons.vue"

import LoanAttachments from "@/@core/components/shared/loans/LoanAttachments.vue"
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import { get } from "lodash";

export default {
  components: {
    BOverlay,
    BRow,
    BCol,
    BAlert,
    BLink,
    BCard,
    BCardBody,
    BButton,
    BCardText,
    BFormCheckbox,
    BFormRadio,
    BFormGroup,
    BPopover,
    BFormFile,
    BForm,
    BFormInput,
    BBadge,
    BCardHeader,
    BAvatar,

    LoanAttachments,
    DeclinedOrFlaggedReasons
  },
  props: {
    manualRepayment: {
      required: true,
      type: Object
    }
  },
  data() {
    return {
      agree_to_terms: false,
      errorMessage: "",
      loan: null
    }
  },
  computed: {
    repaid_amount() {
      return this.getValueFromSource(this.manualRepayment, 'loan.repayments', [])
          .reduce((accumulator, payment) => accumulator + payment.paid_amount, 0)
    },
    amount_paid() {
      const repayment = this.getValueFromSource(this.manualRepayment, 'repayment', null)
      if (repayment) {
        return repayment.paid_amount
      }
      return this.manualRepayment.balance_to_be_paid
    },
    total_penal_charge() {
      return this.getValueFromSource(this.manualRepayment, 'loan.penal_charges', [])
          .reduce((accumulator, penal_charge) => accumulator + penal_charge.charge, 0);
    },
    remaining_balance() {
      const total_amount = this.getValueFromSource(this.manualRepayment, "loan.total_loan_cost", 0);
      return (total_amount + this.total_penal_charge) - this.repaid_amount;
    }
  },
  created() {
    this.requestLoanDetails();
  },
  methods: {
    async cancelPayOffRequest() {
      try {
        this.$emit("loading", true);
        this.errorMessage = '';

        const confirmation_result = await this.$swal({
          title: 'Confirm?',
          text: "Proceed to cancel pay off request.",
          icon: 'warning',
          showCancelButton: true,
          confirmButtonText: 'Yes, proceed',
          customClass: {
            confirmButton: 'btn btn-primary',
            cancelButton: 'btn btn-outline-danger ml-1',
          },
          buttonsStyling: false,
        })

        if (!confirmation_result.value) return;

        const { manual_repayment_id } = this.$route.params;

        await this.useJwt()
            .clientService
            .cancelPayOffRequest(manual_repayment_id);
        this.$emit("fetchDetails");

        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Success',
            icon: 'EditIcon',
            variant: 'success',
            text: "Your loan pay-off request has been cancelled successfully."
          },
        });
      } catch (error) {
        const error_message = get(error, "response.data.message") || error.message
        this.errorMessage = error_message;

        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Error!',
            icon: 'AlertTriangleIcon',
            variant: 'danger',
            text: error_message
          },
        });
      } finally {
        this.$emit("loading", false);
      }
    },
    async requestLoanDetails() {
      const loan_id = this.getValueFromSource(this.manualRepayment, 'loan_id');

      const response = await this.useJwt().clientService.fetchLoanRecord(loan_id);

      this.loan = this.getValueFromSource(response, 'data.data.loan');
    }
  }
}
</script>

<style lang="scss">
.payment-channel-icon {
  width: 50px;
}

.payment-channel-label {
  color: #302861;
  font-weight: bold;
}

.font-active {
  color: #302861
}

.disabled {
  opacity: 0.3;
  cursor: no-drop;
}

.deposit-slip-picker {
  .custom-file-label::after {
    background-color: #302861;
    color: whitesmoke;
    padding-left: 40px;
    padding-right: 40px;
  }
}
</style>
